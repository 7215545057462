import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import NotFound from './NotFound';
import { today } from '../utils/date-time';
import CreateReservation from '../reservations/CreateReservation';
import CreateTable from '../tables/CreateTable';
import AssignTable from '../tables/AssignTable';
import ReservationSearch from '../reservations/ReservationSearch';
import EditReservation from '../reservations/EditReservation';

/**
 * Defines all the routes for the application.
 *
 * You will need to make changes to this file.
 *
 * @returns {JSX.Element}
 */
function Routes() {
	return (
		<Switch>
			<Route exact={true} path='/reservations/:reservation_id/edit'>
				<EditReservation />
			</Route>
			<Route exact={true} path='/search'>
				<ReservationSearch />
			</Route>
			<Route exact={true} path='/reservations/:reservation_id/seat'>
				<AssignTable />
			</Route>
			<Route exact={true} path='/tables/new'>
				<CreateTable />
			</Route>
			<Route exact={true} path='/reservations/new'>
				<CreateReservation />
			</Route>
			<Route exact={true} path='/'>
				<Redirect to={'/dashboard'} />
			</Route>
			<Route exact={true} path='/reservations'>
				<Redirect to={'/dashboard'} />
			</Route>
			<Route path='/dashboard'>
				<Dashboard date={today()} />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	);
}

export default Routes;
